import { Box, Grid, InputLabel, Typography, Paper } from "@mui/material"
import { FullscreenDialog } from "components/FullscreenDialog"
import { FullscreenDialogButton } from "components/FullscreenDialogButton"
import RadioButtons from "components/RadioButtons"

import { useMemo } from "react"
import { createEnumParam, useQueryParams, withDefault } from "use-query-params"
import { useMapDetails } from "./EquivalentPotentialTemperatureMap.service"
import { MapRenderImages } from "./MapRenderer/MapRenderImages"
import { useSpecificMaps, getMapsFromData } from "./AvailableMaps.service"
import { RenderAvailableChildren } from "components/WeatherMaps/MapRenderer/RenderAvailableChildren"
import { FormattedMessage, useIntl } from "react-intl"

export const EquivalentPotentialTemperatureMap = () => {
  const [query, setQuery] = useQueryParams({
    map: withDefault(createEnumParam(["ec00", "ec12"]), "ec00"),
  })
  const intl = useIntl()
  const map = query.map as "ec00" | "ec12"

  const { mapType } = useMapDetails({ ec: map })
  const { data, isSuccess, isLoading } = useSpecificMaps(mapType)
  const images = getMapsFromData(mapType, data)

  const mapTitle = useMemo(() => {
    return `ECMWF ${map === "ec00" ? "00" : "12"} UTC ${intl.formatMessage({
      id: "map_eq_pot_temp_850_long",
    })}`
  }, [map, intl])

  return (
    <>
      <Box pb={4}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Grid item>
            <Typography variant="h2" component="h1">
              {mapTitle}
            </Typography>
          </Grid>

          <Grid item>
            <Grid container alignItems={"center"} spacing={2}>
              <Grid item>
                <InputLabel id="select-map-label">Vis modell:</InputLabel>
              </Grid>
              <Grid item>
                <RadioButtons
                  buttons={[
                    { value: "ec00", label: "EC00" },
                    { value: "ec12", label: "EC12" },
                  ]}
                  onChange={(value: any) =>
                    setQuery({ map: value }, "replaceIn")
                  }
                  selected={map}
                />
              </Grid>
              <Grid item>
                <FullscreenDialogButton
                  type="minimal"
                  dialogID="fullscreen-map"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <RenderAvailableChildren
        images={images}
        isSuccess={isSuccess}
        isLoading={isLoading}
      >
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <FullscreenDialog
              title={mapTitle}
              id="fullscreen-map"
              showChildrenAlsoWhenClosed
            >
              <MapRenderImages imageURLs={images}>
                {(selectedImage) => {
                  return (
                    <img
                      alt="kart over ekvivalent potensiell temperatur"
                      src={selectedImage}
                    />
                  )
                }}
              </MapRenderImages>
            </FullscreenDialog>
          </Grid>
          <Grid item xs={4}>
            <Paper>
              <Typography variant="subtitle1" style={{ padding: 10 }}>
                <FormattedMessage id="map_explanation" />:
              </Typography>
              <Typography variant="body1" style={{ padding: 10 }}>
                <FormattedMessage id="map_eq_pot_temp_850_exp_text" />.
              </Typography>
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", padding: 10 }}
              >
                <FormattedMessage id="map_eq_pot_temp_850_long" />:
              </Typography>
              <Typography variant="body1" style={{ padding: 10 }}>
                <FormattedMessage id="eq_pot_temp_850_exp_text" />.
              </Typography>
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", padding: 10 }}
              >
                <FormattedMessage id="pressure" />:
              </Typography>
              <Typography variant="body1" style={{ padding: 10 }}>
                <FormattedMessage id="eq_pot_temp_850_exp_text_pressure" />.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </RenderAvailableChildren>
    </>
  )
}
