import React from "react"
import { Grid, Theme, useTheme, Typography } from "@mui/material"

import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"

import fogIcon from "../../images/fogIcon.png"
import thunderIcon from "../../images/thunderIcon.png"
import windIcon from "../../images/windIcon.png"
import otherIcon from "../../images/otherIcon.png"
import InfoIcon from '@mui/icons-material/Info';
import { convertToMetersPerSecond, useWindUnit } from "@luna/luna-core"
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: "flex",
    },

    boxHeader: {
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "16px",
      lineHeight: "28px",
      letterSpacing: "0.5px",
    },
    boxHeaderFirst2: {
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "16px",
      lineHeight: "28px",
      letterSpacing: "0.5px",
      [theme.breakpoints.only("xs")]: {
        paddingLeft: theme.spacing(7),
      },
    },
    boxHeaderFirst: {
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "16px",
      lineHeight: "28px",
      letterSpacing: "0.5px",
      paddingLeft: theme.spacing(7),
    },
    boxContent: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "20px",
      paddingBottom: theme.spacing(5),
      paddingTop: theme.spacing(3),
    },
    gridContainerItem: {
      backgroundColor: theme.palette.common.white,
    },
    gridContainerItemLast:{
      backgroundColor: theme.palette.common.white,
      paddingLeft: 30,
    },
    boxContentFirst: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "20px",
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(7),
      paddingBottom: theme.spacing(5),
    },
    boxContentFirst2: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "20px",
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(5),
      [theme.breakpoints.only("xs")]: {
        paddingLeft: theme.spacing(7),
      },
    },
    sigWeatherHeadline: {
      fontSize: "26px",
      fontWeight: "bold",
      fontStyle: "normal",
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(7),
    },
  })
)

interface Props {
  offshoreData: OffshoreData
}

const SignWeather: React.FC<Props> = ({ offshoreData }) => {
  const classes = useStyles()
  const theme = useTheme()
  const { windUnit } = useWindUnit()
  const gale = 34
  let windGale = `${gale} KT`
  if (windUnit === "m/s") {
    windGale = `${convertToMetersPerSecond(gale)} m/s`
  }
  return (
    <>
      <Grid container item xs={12} md={8}>
        <Grid item xs={12} className={classes.gridContainerItem}>
          <Typography className={classes.sigWeatherHeadline}>
            Significant weather first 48 hours:
          </Typography>
        </Grid>
        
        <Grid item xs={6} sm={3} className={classes.gridContainerItem}>
          <Typography className={classes.boxHeaderFirst}>
            <img
              src={fogIcon}
              alt="fog icon"
              style={{ verticalAlign: "middle" }}
            />{" "}
            Fog:
          </Typography>
          <Typography className={classes.boxContentFirst}>
            {offshoreData.fog}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3} className={classes.gridContainerItem}>
          <Typography className={classes.boxHeader}>
            <img
              src={thunderIcon}
              alt="thunder icon"
              style={{ verticalAlign: "middle" }}
            />{" "}
            Thunder:
          </Typography>
          <Typography className={classes.boxContent}>
            {offshoreData.thunder}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3} className={classes.gridContainerItem}>
          <Typography className={classes.boxHeaderFirst2}>
            <img
              src={windIcon}
              alt="wind icon"
              style={{ verticalAlign: "middle" }}
            />{" "}
            Wind: <Tooltip placement="top" arrow title={<Typography variant="h4">(10 m) above gale: {windGale}</Typography>}>
                    <InfoIcon />
                  </Tooltip>
          </Typography>
          <Typography className={classes.boxContentFirst2}>
            {offshoreData.wind}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3} className={classes.gridContainerItemLast}>
          <Typography className={classes.boxHeader}>
            <img
              src={otherIcon}
              alt="other icon"
              style={{ verticalAlign: "middle" }}
            />{" "}
            Other:
          </Typography>
          <Typography className={classes.boxContent}>
            {offshoreData.comment}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={4} justifyContent="center">
        <Grid item xs={12} className={classes.gridContainerItem}>
          <Typography
            variant={"h2"}
            style={{
              paddingTop: theme.spacing(6),
            }}
          >
            &nbsp;
          </Typography>
        </Grid>

        <Grid item xs={6} className={classes.gridContainerItem}>
          <Typography
            className={classes.boxHeader}
            style={{ paddingLeft: theme.spacing(7) }}
          >
            Confidence:
          </Typography>
          <Typography
            className={classes.boxContent}
            style={{ paddingLeft: theme.spacing(7) }}
          >
            {offshoreData.confidence}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.gridContainerItem}>
          <Typography className={classes.boxHeader}>Forecaster:</Typography>
          <Typography className={classes.boxContent}>
            {offshoreData.createdBy}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default SignWeather
