/**
 * filterer let's you filter a list for "every other" item
 * using some constraint like filtering for 2nd or 3rd items,
 * using modulo.
 *
 * It will always preserve first and last items.
 */
export class Filterer<T> {
  list: T[]

  constructor(list: T[]) {
    this.list = list
  }

  filterByEveryN(modulo: number): this {
    this.list = this.list.filter((item, index, theList) => {
      // Always preserve first or last item in list.
      if (index === 0 || index === theList.length - 1) {
        return true
      }
      return index % modulo === 0
    })
    return this
  }

  result(): T[] {
    return this.list
  }
}
