import { Grid, Typography, useTheme } from "@mui/material"

import { FormattedMessage } from "react-intl"
import { TextForecastListRow } from "./TextForecastListRow"

export interface TextForecastValues {
  uri: string
  name: string
}

interface TextForecastProps {
  textforecast: TextForecast[]
}
export const TextForecastOverview: React.FC<TextForecastProps> = ({
  textforecast,
}) => {
  const theme = useTheme()

  return (
    <Grid container sx={{ backgroundColor: theme.palette.background.default }}>
      <Grid
        container
        sx={{ marginBottom: theme.spacing(2) }}
        justifyContent="space-between"
      >
        <Grid item xs={12}>
          <Typography variant={"h1"}>
            <FormattedMessage id="text_forecast" />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {textforecast.map((text) => (
            <TextForecastListRow text={text} key={text.id} />
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}
