import { Box, Grid, InputLabel, Typography, Paper } from "@mui/material"

import { createEnumParam, useQueryParams, withDefault } from "use-query-params"
import { MapRenderImages } from "./MapRenderer/MapRenderImages"
import { useMapDetails } from "./Precipitation3hMap.service"
import { useMemo } from "react"
import { FullscreenDialogButton } from "components/FullscreenDialogButton"
import { FullscreenDialog } from "components/FullscreenDialog"
import RadioButtons from "components/RadioButtons"
import { useSpecificMaps, getMapsFromData } from "./AvailableMaps.service"
import { RenderAvailableChildren } from "components/WeatherMaps/MapRenderer/RenderAvailableChildren"
import { useIntl } from "react-intl"

export const Precipitation3hMap = () => {
  const [query, setQuery] = useQueryParams({
    map: withDefault(createEnumParam(["ec00", "ec12"]), "ec00"),
  })
  const intl = useIntl()
  const map = query.map as "ec00" | "ec12"

  const { mapType } = useMapDetails({ ec: map })
  const { data, isSuccess, isLoading } = useSpecificMaps(mapType)
  const images = getMapsFromData(mapType, data)

  const mapTitle = useMemo(() => {
    return `ECMWF ${map === "ec00" ? "00" : "12"} UTC Nedbør`
  }, [map])

  const mapSubTitle = "3 times prognose (dag 1 tom. dag 3)"

  return (
    <>
      <Box pb={2}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Grid item>
            <Typography variant="h2" component="h1">
              {mapTitle}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"} spacing={2}>
              <Grid item>
                <InputLabel id="select-map-label">
                  {intl.formatMessage({ id: "show_model" })}:
                </InputLabel>
              </Grid>
              <Grid item>
                <RadioButtons
                  buttons={[
                    { value: "ec00", label: "EC00" },
                    { value: "ec12", label: "EC12" },
                  ]}
                  onChange={(value: any) =>
                    setQuery({ map: value }, "replaceIn")
                  }
                  selected={map}
                />
              </Grid>
              <Grid item>
                <FullscreenDialogButton
                  type="minimal"
                  dialogID="fullscreen-map"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">{mapSubTitle}</Typography>
          </Grid>
        </Grid>
      </Box>
      <RenderAvailableChildren
        images={images}
        isSuccess={isSuccess}
        isLoading={isLoading}
      >
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <FullscreenDialog
              title={mapTitle}
              id="fullscreen-map"
              showChildrenAlsoWhenClosed
            >
              <MapRenderImages imageURLs={images}>
                {(selectedImage) => {
                  return <img alt="kart over temperatur" src={selectedImage} />
                }}
              </MapRenderImages>
            </FullscreenDialog>
          </Grid>

          <Grid item xs={4}>
            <Paper>
              <Typography variant="subtitle1" style={{ padding: 10 }}>
                Kartforklaring
              </Typography>
              <Typography variant="body1" style={{ padding: 10 }}>
                Kart viser mengden nedbør i løpet av 3 timer (dag 1 tom. dag 3).
                For å se 6 timer (dag 4 tom. dag 10) velg produktet Nedbør[6t].
              </Typography>
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", padding: 10 }}
              >
                Nedbør:
              </Typography>
              <Typography variant="body1" style={{ padding: 10 }}>
                Nedbøren er vist i henhold til fargeskalaen oppe til høyre i
                kartet, og mengden nedbør er definert i mm i løpet av 3 timer.
              </Typography>
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", padding: 10 }}
              >
                Trykk:
              </Typography>
              <Typography variant="body1" style={{ padding: 10 }}>
                De svarte linjene viser lufttrykket relativt til havnivå i hPa.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </RenderAvailableChildren>
    </>
  )
}
