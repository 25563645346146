import { Paper, Box, Grid, InputLabel, Typography } from "@mui/material"
import { createEnumParam, useQueryParams, withDefault } from "use-query-params"
import { MapRenderImages } from "./MapRenderer/MapRenderImages"
import { useMapDetails } from "./PrecipitationMap.service"
import RadioButtons from "../RadioButtons"
import { useMemo } from "react"
import { FullscreenDialogButton } from "components/FullscreenDialogButton"
import { FullscreenDialog } from "components/FullscreenDialog"
import { useSpecificMaps, getMapsFromData } from "./AvailableMaps.service"
import { RenderAvailableChildren } from "components/WeatherMaps/MapRenderer/RenderAvailableChildren"
import { useIntl } from "react-intl"

export const PrecipitationMap = () => {
  const [query, setQuery] = useQueryParams({
    provider: withDefault(createEnumParam(["ECMWF", "AROME"]), "ECMWF"),
  })
  const intl = useIntl()
  const provider = query.provider as "ECMWF" | "AROME"

  const { mapType } = useMapDetails({ provider })
  const { data, isSuccess, isLoading } = useSpecificMaps(mapType)
  const images = getMapsFromData(mapType, data)

  const mapTitle = useMemo(() => {
    return `Nedbør og trykk neste ${provider === "ECMWF" ? "10" : "2"} døgn`
  }, [provider])

  const mapSubTitle = useMemo(() => {
    return `${provider}: ${
      provider === "ECMWF" ? "12" : "6"
    } timers nedbør, lufttrykk i havnivå, tykkelse mellom 1000 og 500 hpa.`
  }, [provider])

  return (
    <>
      <Box pb={2}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item>
            <Typography variant="h2" component="h1">
              {mapTitle}
            </Typography>
          </Grid>

          <Grid item>
            <Grid item container alignItems={"center"} spacing={2}>
              <Grid item>
                <InputLabel id="select-map-label">
                  {intl.formatMessage({ id: "show_model" })}:
                </InputLabel>
              </Grid>
              <Grid item>
                <RadioButtons
                  buttons={[
                    { value: "AROME", label: "Arome - korttid" },
                    { value: "ECMWF", label: "ECMWF - langtid" },
                  ]}
                  onChange={(value: any) =>
                    setQuery({ provider: value }, "replaceIn")
                  }
                  selected={provider}
                />
              </Grid>
              <Grid item>
                <FullscreenDialogButton
                  type="minimal"
                  dialogID="fullscreen-map"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1">{mapSubTitle}</Typography>
          </Grid>
        </Grid>
      </Box>
      <RenderAvailableChildren
        images={images}
        isSuccess={isSuccess}
        isLoading={isLoading}
      >
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <FullscreenDialog
              title={mapTitle}
              id="fullscreen-map"
              showChildrenAlsoWhenClosed
            >
              <MapRenderImages imageURLs={images}>
                {(selectedImage) => {
                  return <img alt="kart over temperatur" src={selectedImage} />
                }}
              </MapRenderImages>
            </FullscreenDialog>
          </Grid>

          <Grid item xs={5}>
            <Paper>
              <Typography variant="subtitle1" style={{ padding: 10 }}>
                Kartforklaring
              </Typography>
              <Typography variant="body1" style={{ padding: 10 }}>
                Kart viser mengden nedbør i løpet av 6 timer [Arome] og 12 timer
                [ECMWF].
              </Typography>
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", padding: 10 }}
              >
                Nedbør:
              </Typography>
              <Typography variant="body1" style={{ padding: 10 }}>
                Nedbøren er vist i henhold til fargeskalaen oppe til høyre i
                kartet, og mengden nedbør er definert i mm i løpet av 6 [Arome]
                eller 12 [ECMWF] timer.
              </Typography>
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", padding: 10 }}
              >
                Trykk:
              </Typography>
              <Typography variant="body1" style={{ padding: 10 }}>
                De blå linjene viser lufttrykket relativt til havnivå i hPa.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </RenderAvailableChildren>
    </>
  )
}
