import { Box, Grid, Typography, Paper } from "@mui/material"
import { FullscreenDialog } from "components/FullscreenDialog"
import { FullscreenDialogButton } from "components/FullscreenDialogButton"
import { RenderAvailableChildren } from "components/WeatherMaps/MapRenderer/RenderAvailableChildren"
import { getMapsFromData, useSpecificMaps } from "./AvailableMaps.service"
import { MapRenderImages } from "./MapRenderer/MapRenderImages"
import { useMapDetails } from "./WindMap.service"

const mapTitle = "Vind Skandinavia"

/**
 * This component shows two Weather images side by side. And
 * lets you compare them.
 */
export const WindMap = () => {
  const { mapType } = useMapDetails()
  const { data, isSuccess, isLoading } = useSpecificMaps(mapType)
  const images = getMapsFromData(mapType, data)

  return (
    <>
      <Box pb={2}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Grid item>
            <Typography variant="h2" component="h1">
              {mapTitle}
            </Typography>
          </Grid>
          <Grid item>
            <FullscreenDialogButton type="minimal" dialogID="fullscreen-map" />
          </Grid>
        </Grid>
      </Box>
      <RenderAvailableChildren
        images={images}
        isSuccess={isSuccess}
        isLoading={isLoading}
      >
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <FullscreenDialog
              title={mapTitle}
              id="fullscreen-map"
              showChildrenAlsoWhenClosed
            >
              <MapRenderImages imageURLs={images}>
                {(selectedImage) => {
                  return <img alt="kart over vind" src={selectedImage} />
                }}
              </MapRenderImages>
            </FullscreenDialog>
          </Grid>

          <Grid item xs={4}>
            <Paper>
              <Typography variant="subtitle1" style={{ padding: 10 }}>
                Kartforklaring
              </Typography>
              <Typography variant="body1" style={{ padding: 10 }}>
                Kart viser vind 10 meter over bakken. Vinden er vist med både
                vindpiler og med fargepalett i henhold til fargeskalaen oppe til
                høyre i kartet i m/s.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </RenderAvailableChildren>
    </>
  )
}
