import { Box, Grid, Typography, TextField } from "@mui/material"
import { DateTime } from "luxon"
import { useMarks } from "../WeatherMaps/MapRenderer/UseMarks.service"
import { MapRenderImages } from "../WeatherMaps/MapRenderer/MapRenderImages"

import { useMemo, useState } from "react"
import { FullscreenDialogButton } from "components/FullscreenDialogButton"
import { FullscreenDialog } from "components/FullscreenDialog"
import { RenderAvailableChildren } from "components/WeatherMaps/MapRenderer/RenderAvailableChildren"
import { useRadar } from "./Radar.service"
import { config } from "../../config"
import { FormattedMessage } from "react-intl"

type Props = { radar: Radar; index: number }

// å tillate kunden å velge skal pr nå kun være mulig for akkumulert nedbør
const configureableTypes: Radar["type"][] = [
  "accumulated_01h",
  "accumulated_02h",
  "accumulated_03h",
  "accumulated_04h",
  "accumulated_05h",
  "accumulated_06h",
  "accumulated_07h",
  "accumulated_08h",
  "accumulated_09h",
  "accumulated_10h",
  "accumulated_11h",
  "accumulated_12h",
  "accumulated_13h",
  "accumulated_14h",
  "accumulated_15h",
  "accumulated_16h",
  "accumulated_17h",
  "accumulated_18h",
  "accumulated_19h",
  "accumulated_20h",
  "accumulated_21h",
  "accumulated_22h",
  "accumulated_23h",
  "accumulated_24h",
]

const configureableAreas: Radar["area"][] = [
  "central_norway",
  "eastern_norway",
  "finnmark",
  "nordic",
  "nordland",
  "northern_nordland",
  "northwestern_norway",
  "norway",
  "southeastern_norway",
  "southern_nordland",
  "southern_norway",
  "southwestern_norway",
  "troms",
  "western_norway",
  "xband",
]

export const Radar: React.FC<Props> = ({ radar, index }) => {
  const { product_name, area, type, time, sequence, id } = radar

  const isAreaEditable = area === "kunden_velger"
  const isTypeEditable = type === "kunden_velger"

  const [configuredArea, setConfiguredArea] = useState<Radar["area"]>(
    isAreaEditable ? "eastern_norway" : area
  )
  const [configuredType, setConfiguredType] = useState<Radar["type"]>(
    isTypeEditable ? "accumulated_01h" : type
  )
  const [configuredTime, setConfiguredTime] = useState<string>(time.toString())

  const { data, isSuccess, isLoading } = useRadar({
    product_name,
    area: configuredArea,
    type: configuredType,
    time: DateTime.fromISO(configuredTime),
    content: "image",
    sequence,
  })

  const images = (data || [])
    .filter((e) => e)
    .map(({ uri = "" }) => uri.replace(config.api.weatherUrl, ""))
    .filter((e) => e)

  const { marks } = useMarks({
    type: "params",
    steps: data || [],
    filterBy: (filterer) =>
      filterer.filterByEveryN(2).filterByEveryN(2).filterByEveryN(2).result(),
  })

  const mapTitle = useMemo(() => {
    return `${product_name}`
  }, [product_name])
  const idQuery = useMemo(() => id.slice(0, 7), [id])
  const fullscreenQuery = useMemo(() => `fullscreen-${idQuery}`, [idQuery])

  return (
    <>
      <Box pb={4}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          wrap={"nowrap"}
        >
          <Grid item sm={8}>
            <Typography variant="subtitle1" component="h3">
              <FormattedMessage id={radar.area} />
              {" - "}
              <FormattedMessage id={radar.type} />
            </Typography>
          </Grid>

          <Grid item alignItems={"flex-end"}>
            <Grid
              item
              container
              alignItems={"center"}
              spacing={1}
              wrap={"nowrap"}
            >
              <Grid item>
                <FullscreenDialogButton
                  type="minimal"
                  dialogID={fullscreenQuery}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {(isTypeEditable || isAreaEditable) && (
        <TextField
          fullWidth
          onBlur={(event) => {
            setConfiguredTime(event.target.value)
          }}
          defaultValue={configuredTime}
          type="datetime-local"
          label="Tidspunkt"
          variant="outlined"
        />
      )}
      {isTypeEditable && (
        <TextField
          onChange={(event) => {
            setConfiguredType(event.target.value as Radar["type"])
          }}
          value={configuredType}
          fullWidth
          type="text"
          label="Type"
          select
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >
          {configureableTypes.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </TextField>
      )}
      {isAreaEditable && (
        <TextField
          onChange={(event) => {
            setConfiguredArea(event.target.value as Radar["area"])
          }}
          fullWidth
          value={configuredArea}
          type="text"
          label="Område"
          select
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >
          {configureableAreas.map((area) => (
            <option key={area} value={area}>
              {area}
            </option>
          ))}
        </TextField>
      )}
      <RenderAvailableChildren
        images={images}
        isSuccess={isSuccess}
        isLoading={isLoading}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FullscreenDialog
              title={mapTitle}
              id={fullscreenQuery}
              showChildrenAlsoWhenClosed
            >
              <MapRenderImages
                queryParam={idQuery}
                imageURLs={images}
                marks={marks}
              >
                {(selectedImage) => {
                  return (
                    <img
                      alt={`værradar kart for ${area} og type ${type}`}
                      src={selectedImage}
                    />
                  )
                }}
              </MapRenderImages>
            </FullscreenDialog>
          </Grid>
        </Grid>
      </RenderAvailableChildren>
    </>
  )
}
