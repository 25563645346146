import { Assignment, Radar } from "@mui/icons-material"
import {
  Box,
  Breadcrumbs,
  Container,
  Tab,
  Tabs,
  Typography,
} from "@mui/material"
import OffshoreWeatherReport, {
  useOffshoreData,
} from "components/Offshore/OffshoreWeatherReport"
import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import { useUserProducts } from "components/UserProductsProvider"
import LastPublishedWhen from "components/LastUpdatedWhen"
import { Link } from "react-router-dom"
import { OffshoreRadarPage } from "./OffshoreRadarPage"

const OffshorePage = () => {
  const userProducts = useUserProducts()
  const [value, setValue] = useState(0)
  const hasRadar = userProducts.hasProduct("radar")
  const { radars } = userProducts
  const { report } = useOffshoreData()

  const stationName = report?.offshoreData.position_name

  const { offshoreData } = report || {}
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  return (
    <>
      {hasRadar && radars.length > 0 && (
        <Box>
          <Tabs
            centered
            value={value}
            onChange={handleChange}
            sx={{
              px: 0,
              backgroundColor: "white",
            }}
          >
            <Tab
              label={<FormattedMessage id="weatherForecast" />}
              {...a11yProps(0)}
              icon={<Assignment />}
              sx={{
                width: 250,
              }}
            />
            <Tab
              label="Radar"
              {...a11yProps(1)}
              icon={<Radar />}
              sx={{ width: 250 }}
            />
          </Tabs>
        </Box>
      )}
      <Container>
        <Box pt={4} pb={3} pl={3}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/">Luna</Link>
            <Typography color="textPrimary">{stationName}</Typography>
          </Breadcrumbs>
        </Box>
      </Container>

      <Container>
        <Box paddingLeft={3}>
          <Typography variant={"h1"}>
            <FormattedMessage id={value === 0 ? "weatherForecast" : "Radar"} />{" "}
            for {stationName}
          </Typography>
        </Box>
      </Container>
      <TabPanel value={value} index={0}>
        <Container>
          <Box paddingLeft={3}>
            <Typography variant="body2">
              <LastPublishedWhen date={offshoreData?.published || ""} />
            </Typography>
          </Box>

          <OffshoreWeatherReport />
        </Container>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <OffshoreRadarPage />
      </TabPanel>
    </>
  )
}

export default OffshorePage

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  }
}

interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}
