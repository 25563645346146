import React, { useContext, useMemo } from "react"
import { useEffect, useState } from "react"
import { getUserProducts } from "services/dataFetcher"
import {
  OffshoreProductID,
  MapProductID,
  CommonProductID,
} from "./UserProductIDs.service"
import LoadingSpinner from "./LoadingSpinner"
import { useOffshoreSummary } from "components/Offshore/useOffshoreSummary.hook"

type ContextType = UserProducts & {
  customerType: "energy" | "offshore"
}

const initialValue: ContextType = {
  user: "",
  customer: "",
  products: [],
  offshoreOrders: [],
  meteograms: [],
  text_forecasts: [],
  radars: [],
  data_downloads: [],
  customerType: "energy",
}

const UserProductsContext = React.createContext<ContextType>(initialValue)

type Props = {
  children: (data: ContextType) => JSX.Element
}

export const UserProductsProvider = ({ children }: Props) => {
  const [userProducts, setUserProducts] = useState<UserProducts | undefined>(
    undefined
  )
  useEffect(() => {
    getUserProducts()
      .then((data) => {
        setUserProducts(data)
      })
      .catch((err: Error) => {
        const message = "Failed to load user products"
        console.log(message, err)
        // Re-throw error so it's caught by <ErrorBoundary />
        throw new Error(message)
      })
  }, [])

  const { offshoreSummary } = useOffshoreSummary()

  const value: ContextType | undefined = useMemo(() => {
    if (userProducts === undefined || offshoreSummary === undefined) {
      return
    }
    return {
      ...userProducts,
      customerType: offshoreSummary.length > 0 ? "offshore" : "energy",
    }
  }, [userProducts, offshoreSummary])

  //  Do not render anything until backend has responded with products.
  if (value === undefined) {
    return <LoadingSpinner />
  }

  return (
    <UserProductsContext.Provider value={value}>
      {children(value)}
    </UserProductsContext.Provider>
  )
}

export const useUserProducts = () => {
  const ctx = useContext(UserProductsContext)
  return {
    ...ctx,
    hasProduct(
      productId: string
    ): productId is CommonProductID | OffshoreProductID | MapProductID {
      return ctx.products.includes(productId)
    },
  }
}
