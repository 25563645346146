import { Paper, Box, Grid, InputLabel, Typography } from "@mui/material"
import { FullscreenDialog } from "components/FullscreenDialog"
import { FullscreenDialogButton } from "components/FullscreenDialogButton"
import {
  createEnumParam,
  NumberParam,
  useQueryParams,
  withDefault,
} from "use-query-params"
import RadioButtons from "../RadioButtons"
import { useMapDetails, Millimeter } from "./ChanceOfRainMap.service"
import { FormattedMessage } from "react-intl"
import { MapRenderImages } from "./MapRenderer/MapRenderImages"
import { useSpecificMaps, getMapsFromData } from "./AvailableMaps.service"
import { RenderAvailableChildren } from "components/WeatherMaps/MapRenderer/RenderAvailableChildren"

const mapTitle = "Sannsynlighet nedbør"

/**
 * This component shows two Weather images side by side. And
 * lets you compare them.
 */
export const ChanceOfRainMap = () => {
  const [query, setQuery] = useQueryParams({
    millimeter: withDefault(NumberParam, 5.0),
    provider: withDefault(createEnumParam(["ECMWF", "AROME"]), "AROME"),
  })
  const milimeters = query.millimeter as Millimeter
  const provider = query.provider as "ECMWF" | "AROME"
  const period = "24t"

  const { mapType } = useMapDetails({
    millimeters: milimeters,
    provider,
    period,
    type: "standard",
  })

  const { data, isSuccess, isLoading } = useSpecificMaps(mapType)
  const images = getMapsFromData(mapType, data)

  return (
    <>
      <Box pb={4}>
        <Box pb={4}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h2" component="h1">
                {mapTitle}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container alignItems={"center"} spacing={2}>
                <Grid item>
                  <InputLabel id="select-map-label">
                    <FormattedMessage id="display_model" />:
                  </InputLabel>
                </Grid>
                <Grid item>
                  <RadioButtons
                    buttons={[
                      { value: "AROME", label: "Arome - korttid" },
                      { value: "ECMWF", label: "ECMWF - langtid" },
                    ]}
                    onChange={(value: any) =>
                      setQuery({ provider: value }, "replaceIn")
                    }
                    selected={provider}
                  />
                </Grid>
                <Grid item>
                  <FullscreenDialogButton
                    type="minimal"
                    dialogID="fullscreen-map"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box pb={2}>
          <Typography variant="subtitle1">
            Sannsynlighet nedbør over:
          </Typography>
        </Box>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item>
            <RadioButtons
              buttons={[
                { label: "5.0 mm", value: 5.0 },
                { label: "10 mm", value: 10 },
                { label: "20 mm", value: 20 },
                { label: "40 mm", value: 40 },
                { label: "60 mm", value: 60 },
                { label: "80 mm", value: 80 },
                { label: "100 mm", value: 100 },
              ]}
              onChange={(value: any) =>
                setQuery({ millimeter: value }, "replaceIn")
              }
              selected={milimeters}
            />
          </Grid>
        </Grid>
      </Box>
      <RenderAvailableChildren
        images={images}
        isSuccess={isSuccess}
        isLoading={isLoading}
      >
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <FullscreenDialog
              title={mapTitle}
              id="fullscreen-map"
              showChildrenAlsoWhenClosed
            >
              <MapRenderImages imageURLs={images}>
                {(selectedImage) => {
                  return (
                    <img
                      alt="kart over sannsynlighet nedbør"
                      src={selectedImage}
                    />
                  )
                }}
              </MapRenderImages>
            </FullscreenDialog>
          </Grid>

          <Grid item xs={4}>
            <Paper>
              <Typography variant="subtitle1" style={{ padding: 10 }}>
                Kartforklaring
              </Typography>
              <Typography variant="body1" style={{ padding: 10 }}>
                Kart viser sannsynlighet for valgt antall millimeter nedbør over
                de siste 24 eller 48 timer. Sannsynligheten er vist i henhold
                til fargeskalaen nede til høyre i kartet, og er gitt i prosent.
              </Typography>

              <Typography variant="body1" style={{ padding: 10 }}>
                I valgmenyen kan en velge antall millimeter nedbør en vil se
                sannsynlighet, og en kan velge mellom modellene Arome og ECMWF.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </RenderAvailableChildren>
    </>
  )
}
