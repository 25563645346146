import React from "react"

import { useIntl } from "react-intl"
import { useTimezone, useWindUnit } from "@luna/luna-core"

import { Grid, Theme, Typography } from "@mui/material"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import { FileDownload } from "@mui/icons-material"

import { config } from "../../../config"

import { useFileDownloader } from "services/dataFetcher"

import moment from "moment"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      backgroundColor: theme.palette.common.white,
      border: `2px solid ${theme.palette.background.default}`,
      padding: theme.spacing(2),
    },
  })
)

interface AvailbleProps {
  available: AvailableArchivedForecast
  stationName: string
}

const OffshoreHistoryListRow: React.FC<AvailbleProps> = ({
  available,
  stationName,
}) => {
  const intl = useIntl()
  const { currentTimezone } = useTimezone()
  const classes = useStyles()
  const { windUnit } = useWindUnit()
  const { downloadFile } = useFileDownloader()

  const date: string = moment(available.validFrom)
    .locale(intl.locale)
    .tz(currentTimezone)
    .format("DD.MM.YYYY HH zz")
  const csvURL: string = `/api/v1/products/offshore/reports/${available.reportID}/csv?windunit=${windUnit}&source=archive`
  const fileName: string = `Offshore Weather for ${stationName} valid from ${date} by MET NORWAY`

  return (
    <Grid
      container
      className={classes.row}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid
        container
        item
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        xs={9}
      >
        <Grid item xs={12} sm={3}>
          <Typography>{date}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography>
            CSV{"   "}
            <a
              href={`${config.api.lunaUrl}${csvURL}`}
              onClick={(e) => {
                // Cancel regular href behavior, before triggering download.
                e.preventDefault()
                downloadFile({ url: csvURL, filename: fileName + ".csv" })
              }}
            >
              <FileDownload
                style={{ verticalAlign: "middle", color: "#000" }}
              />
            </a>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default OffshoreHistoryListRow
