import { Box, Grid, InputLabel, Typography, Paper } from "@mui/material"
import { createEnumParam, useQueryParams, withDefault } from "use-query-params"
import { MapRenderImages } from "./MapRenderer/MapRenderImages"
import { useMapDetails } from "./Temperature850Map.service"
import { useMemo } from "react"
import { FullscreenDialogButton } from "components/FullscreenDialogButton"
import { FullscreenDialog } from "components/FullscreenDialog"
import RadioButtons from "components/RadioButtons"
import { useSpecificMaps, getMapsFromData } from "./AvailableMaps.service"
import { RenderAvailableChildren } from "components/WeatherMaps/MapRenderer/RenderAvailableChildren"
import { useIntl } from "react-intl"

export const Temperature850Map = () => {
  const [query, setQuery] = useQueryParams({
    map: withDefault(createEnumParam(["ec00", "ec12"]), "ec00"),
  })

  const intl = useIntl()
  const map = query.map as "ec00" | "ec12"

  const { mapType } = useMapDetails({ ec: map })
  const { data, isSuccess, isLoading } = useSpecificMaps(mapType)
  const images = getMapsFromData(mapType, data)

  const mapTitle = useMemo(() => {
    return `ECMWF ${map === "ec00" ? "00" : "12"} UTC Temperatur 850 hPa`
  }, [map])

  return (
    <>
      <Box pb={2}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Grid item>
            <Typography variant="h2" component="h1">
              {mapTitle}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"} spacing={2}>
              <Grid item>
                <InputLabel id="select-map-label">
                  {intl.formatMessage({ id: "show_model" })}:
                </InputLabel>
              </Grid>
              <Grid item>
                <RadioButtons
                  buttons={[
                    { value: "ec00", label: "EC00" },
                    { value: "ec12", label: "EC12" },
                  ]}
                  onChange={(value: any) =>
                    setQuery({ map: value }, "replaceIn")
                  }
                  selected={map}
                />
              </Grid>
              <Grid item>
                <FullscreenDialogButton
                  type="minimal"
                  dialogID="fullscreen-map"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <RenderAvailableChildren
        images={images}
        isSuccess={isSuccess}
        isLoading={isLoading}
      >
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <FullscreenDialog
              title={mapTitle}
              id="fullscreen-map"
              showChildrenAlsoWhenClosed
            >
              <MapRenderImages imageURLs={images}>
                {(selectedImage) => {
                  return <img alt="kart over temperatur" src={selectedImage} />
                }}
              </MapRenderImages>
            </FullscreenDialog>
          </Grid>
          <Grid item xs={4}>
            <Paper>
              <Typography variant="subtitle1" style={{ padding: 10 }}>
                Kartforklaring
              </Typography>
              <Typography variant="body1" style={{ padding: 10 }}>
                Kart viser temperatur i trykkflaten 850 hPa og Geopotensial i
                trykkflaten 850 hPa.
              </Typography>
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", padding: 10 }}
              >
                Temperatur 850 hPa:
              </Typography>
              <Typography variant="body1" style={{ padding: 10 }}>
                Temperatur i 850 hPa er vist i henhold til fargeskalaen oppe til
                høyre i kartet og vises i grader Celsius.
              </Typography>
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", padding: 10 }}
              >
                Geopotensiell høyde 850 hPa:
              </Typography>
              <Typography variant="body1" style={{ padding: 10 }}>
                De svarte linjene viser høyden på trykkflaten i 850 hPa høyde,
                også kalt geopotensial. .
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </RenderAvailableChildren>
    </>
  )
}
