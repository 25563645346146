import { Box, Container, Typography } from "@mui/material"
import { FormattedMessage } from "react-intl"
import OffshoreHistory from "../OffshoreHistory/OffshoreHistory"

interface Props {
  report: OffshoreReportData
}

export const OffshoreMenuHistory: React.FC<Props> = ({ report }) => {
  const stationName = report.offshoreData.position_name

  return (
    <Container maxWidth={"xl"}>
      <Box paddingLeft={2} paddingBottom={3}>
        <Typography variant={"h1"}>
          <FormattedMessage id="history" />
        </Typography>
      </Box>

      <Box paddingLeft={2} paddingRight={2} paddingBottom={5}>
        <OffshoreHistory
          stationName={stationName}
          orderId={report.offshoreData.order_id}
        />
      </Box>
    </Container>
  )
}
