import { useTheme, Theme } from "@mui/material/styles"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import React, { CSSProperties, ReactElement } from "react"
import toNumber from "lodash/toNumber"
import { ColumnGroup, Column, RowData, ColumnID } from "./OffshoreTable"
import { ActiveCriteria } from "./OffshoreTable.hook"

const MetTableRow: React.FunctionComponent<{
  columnGroups: ColumnGroup[]
  row: RowData
  activeCriteria: ActiveCriteria
  backgroundColor: string
}> = ({ columnGroups, row, activeCriteria, backgroundColor }) => {
  const theme = useTheme()
  let firstColumnBackgroundColor: string | undefined
  const cellsInRow = columnGroups.reduce<(ReactElement | null)[]>(
    (acc, columnGroup, columnGroupIndex) => {
      const { columns } = columnGroup
      columns.forEach((column, columnIndex) => {
        const formattedValue = getFormattedCellValue({
          column,
          row,
        })
        const isLastInColumnGroup = columnIndex === columns.length - 1
        const isLastColumnInRow =
          isLastInColumnGroup && columnGroupIndex === columnGroups.length - 1
        const columnBackgroundColor = getColumnBackgroundColor({
          id: column.id,
          value: formattedValue,
          theme,
          activeCriteria,
        })
        /**
         * When looping, if we encounter a red or yellow treshold styling
         * we 'copy' it to the firstColumnStyle variable to be used later
         * for styling the very first column.
         */
        if (columnBackgroundColor === theme.palette.error.main) {
          firstColumnBackgroundColor = columnBackgroundColor
        } else if (
          firstColumnBackgroundColor !== theme.palette.error.main &&
          columnBackgroundColor === theme.palette.warning.main
        ) {
          firstColumnBackgroundColor = columnBackgroundColor
        }
        const isFirstColumnInColumnGroup = columnIndex === 0
        const isFirstColumnGroup = columnGroupIndex === 0
        const shouldHaveRightBorder = isLastInColumnGroup && !isLastColumnInRow
        const isFirstColumnInRow =
          isFirstColumnGroup && isFirstColumnInColumnGroup
        const tableCell = (
          <TableCell
            key={`${column.id}`}
            align={column.align}
            component={isFirstColumnInRow ? ("th" as any) : ("td" as any)}
            sx={{
              "&:first-of-type": {
                whiteSpace: "nowrap",
              },
              transition: "background-color 0.5s ease-out",
              ...(columnBackgroundColor && {
                backgroundColor: columnBackgroundColor,
              }),
              ...(shouldHaveRightBorder && {
                borderRight: `1px solid ${theme.palette.common.black}`,
              }),
              ...(isFirstColumnInRow && {
                position: "sticky",
                left: 0,
                zIndex: 2,
                backgroundColor,
              }),
            }}
          >
            {formattedValue}
          </TableCell>
        )
        acc.push(tableCell)
      })
      return acc
    },
    []
  )

  /**
   * Style first column with any treshold colors that might be
   * in the row.
   */
  cellsInRow[0] = updateFirstCellStyle({
    cell: cellsInRow[0],
    newStyle: { backgroundColor: firstColumnBackgroundColor },
  })

  return (
    <TableRow tabIndex={-1} hover role="checkbox" sx={{ backgroundColor }}>
      {cellsInRow}
    </TableRow>
  )
}

export default MetTableRow

const updateFirstCellStyle = ({
  cell,
  newStyle,
}: {
  cell: ReactElement | null
  newStyle: CSSProperties
}): ReactElement | null => {
  if (!cell) {
    return cell
  }
  const { props: { style = {}, ...restOfProps } = {}, ...restOfCell } = cell
  const newCell = {
    ...restOfCell,
    props: { style: { ...style, ...newStyle }, ...restOfProps },
  }
  return newCell
}

const getColumnBackgroundColor = ({
  id,
  value,
  theme,
  activeCriteria,
}: {
  id: ColumnID
  value: string
  theme: Theme
  activeCriteria?: ActiveCriteria
}): string | undefined => {
  if (!activeCriteria) {
    return
  }

  if (
    checkIsValueAboveOrEqualTreshold({
      value,
      treshold: activeCriteria.red[id],
    })
  ) {
    return theme.palette.error.main
  } else if (
    checkIsValueAboveOrEqualTreshold({
      value,
      treshold: activeCriteria.yellow[id],
    })
  ) {
    return theme.palette.warning.main
  }
  return
}

export const checkIsValueAboveOrEqualTreshold = ({
  value,
  treshold,
}: {
  value: string
  treshold: string
}) => {
  if (treshold === undefined) {
    return false
  }

  if (value === "" || treshold === "") {
    // No use comparing, skip
    return false
  }

  if (treshold.startsWith("<")) {
    return toNumber(treshold.replace("<", "")) >= toNumber(value)
  }

  return toNumber(treshold) <= toNumber(value)
}

const getFormattedCellValue = ({
  column,
  row,
}: {
  column: Column
  row: RowData
}): string => {
  const value = row[column.id]
  const formattedValue =
    !!value && column.format ? column.format(value as any) : value
  return formattedValue as string
}
