/**
 * This file contains helpers and string definitions of the various
 * product IDs that are available throughout the application.
 */

export type OffshoreProductID = "exww" | "exwwoutlook" | "text_forecast"

export type CommonProductID = "meteogram" | "radar"

export const allMapProductIDs = [
  "accumulated_rain",
  "chance_of_rain",
  "jet_winds",
  "humidity",
  "temperature",
  "precipitation",
  "precipitation3h",
  "precipitation6h",
  "temperature850",
  "wind",
  "equivalPotTemp",
] as const

export type MapProductID = (typeof allMapProductIDs)[number]

export const isMapProduct = (productId: string): productId is MapProductID => {
  return allMapProductIDs.includes(productId as MapProductID)
}
