import { Grid } from "@mui/material"
import { useOffshoreSummary } from "components/Offshore/useOffshoreSummary.hook"
import { useSpecialForecasts } from "components/Offshore/SpecialForecast/SpecialForecasts.service"
import { useUserProducts } from "components/UserProductsProvider"
import React from "react"
import LeafletMap from "components/LeafletMap"
import OffshoreOverview from "components/Offshore/OffshoreOverview"
import SpecialForecastOverview from "components/Offshore/SpecialForecast/SpecialForecastOverview"
import { MessageAlerts, useMessages } from "components/Message/MessageAlerts"
import { TextForecastOverview } from "components/TextForecast/TextForecastOverview"

const FrontPageOffshore: React.FC = () => {
  const userProducts = useUserProducts()
  const { specialForecasts } = useSpecialForecasts({ userProducts })
  const { offshoreSummary } = useOffshoreSummary({ autoRefresh: true })
  const { messages } = useMessages({ autoRefresh: true })
  const { text_forecasts = [] } = userProducts

  const hasTextforecast =
    userProducts.hasProduct("text_forecast") && text_forecasts.length > 0

  return (
    <Grid container spacing={4} sx={{ py: 4, px: 0 }}>
      <MessageAlerts messages={messages} />
      <Grid
        container
        direction="column"
        item
        xs={12}
        md={9}
        justifyContent="flex-start"
      >
        {offshoreSummary && offshoreSummary?.length > 0 && (
          <Grid
            item
            sx={{
              display: "flex",
              pb: 4,
            }}
          >
            <OffshoreOverview props={offshoreSummary} />
          </Grid>
        )}
        {specialForecasts !== undefined && specialForecasts.length > 0 && (
          <Grid
            item
            sx={{
              display: "flex",
              pb: 4,
            }}
          >
            <SpecialForecastOverview forecasts={specialForecasts} />
          </Grid>
        )}

        {hasTextforecast && (
          <Grid
            item
            sx={{
              display: "flex",
              pb: 4,
            }}
          >
            <TextForecastOverview textforecast={text_forecasts} />
          </Grid>
        )}
      </Grid>

      <Grid
        item
        xs={12}
        md={3}
        sx={{
          display: "flex",
          pb: 4,
        }}
      >
        {offshoreSummary && offshoreSummary?.length > 0 && (
          <LeafletMap {...offshoreSummary} />
        )}
      </Grid>
    </Grid>
  )
}

export default FrontPageOffshore
